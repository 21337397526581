import {LitElement, html, customElement} from '../../node_modules/lit-element';

@customElement('nonstop-footer')
export class FooterElement extends LitElement {

    protected override createRenderRoot() {
        return this;
    }
    
    override render() {
        return html`
            <footer class="footer">
                <div class="container">
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <h3>Links</h3>
                            </div>

                            <ul class="twitter-widget footer-links">
                                <li><a href="#"> Home </a></li>
                                <li><a href="#"> About Us </a></li>
                                <li><a href="#"> Services</a></li>
                                <li><a href="#"> Properties</a></li>
                                <li><a href="#"> Contact</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <h3>Contact Details</h3>
                            </div>

                            <ul class="footer-links">
                                <li><a href="mailto:#">info@nonstoprealestate.com.au</a></li>
                                <li><a href="#">www.nonstoprealestate.com.au</a></li>
                                <li>PO Box 3208, Wareemba, NSW 2046, Australia</li>
                                <li>+61 468 366 567</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <h3>Social</h3>
                            </div>
                            <ul class="footer-links">
<!--                                <li><a href="#"><i class="fa fa-facebook"></i> Facebook</a></li>-->
<!--                                <li><a href="#"><i class="fa fa-twitter"></i> Twitter</a></li>-->
<!--                                <li><a href="#"><i class="fa fa-linkedin-square"></i> LinkedIn</a></li>-->
                                <li><a href="#"><i class="fa fa-instagram"></i> Instagram</a></li>
                                
                                <li class="dropdown-toggle" id="wechatDropdown" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><a href="#"><i class="fa fa-wechat global-radius"></i> Wechat</a></li>

                                <form action="#" class="p-color-bg dropdown-menu tran3s" aria-labelledby="wechatDropdown">
                                    <img id="wechat" src="../../images/wechat-qr-code.png" style="max-height: 240px; max-width: 240px;" />
                                </form>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <div class="copyrights">
                <div class="container">
                    <div class="footer-distributed">
                        <div class="footer-left">
                            <p class="footer-company-name">All Rights Reserved. &copy; 2021 <a href="#">Nonstop Real Estate</a></p>
                        </div>

                        <div class="footer-right">

                        </div>
                    </div>
                </div>
            </div>

<!--            <a href="#" id="scroll-to-top" class="dmtop global-radius"><i class="fa fa-angle-up"></i></a>-->
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'nonstop-footer': FooterElement;
    }
}
